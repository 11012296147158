<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-lg-8">
          <h3>
            {{ ingredient.label }}
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <button
            class="btn btn-light btn-sm"
            @click.prevent="displayIngredientForm = !displayIngredientForm"
          >
            <feather-icon icon="EditIcon" />
          </button>
        </div>
      </div>
      <div v-if="displayIngredientForm == true" class="m-lg-2 mb-2">
        <h3>Modifier cet ingrédient</h3>
        <ingredient-form
          :ingredient-id="ingredient.id"
          :display.sync="displayIngredientForm"
          @updated="onUpdate"
          @deleted="onDelete"
        />
      </div>
      <dl v-else class="row">
        <dt class="col-sm-3 text-right">#</dt>
        <dd class="col-sm-9">
          <b><copy-value :value="ingredient.id" /></b>
        </dd>
        <dt class="col-sm-3 text-right">Statut</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.status }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Famille</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.family }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Intitulé public</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.public_label || '-' }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Exclu des listes</dt>
        <dd class="col-sm-9">
          <feather-icon
            v-if="ingredient.is_excluded_from_public_list === true"
            class="text-success"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </dd>
        <dt class="col-sm-3 text-right">Code</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.product_code }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Fournisseur</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.suplier }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Code fournisseur</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.suplier_reference || 'NC' }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Unité de conditionnement</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.unit }}</b>
        </dd>
        <dt class="col-sm-3 text-right">Prix</dt>
        <dd class="col-sm-9">
          <b>{{ ingredient.price | currency }} HT</b>
        </dd>
        <dt class="col-sm-3 text-right">Bio</dt>
        <dd class="col-sm-9">
          <feather-icon
            v-if="ingredient.is_organic === true"
            class="text-success"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </dd>
        <dt class="col-sm-3 text-right">Local</dt>
        <dd class="col-sm-9">
          <feather-icon
            v-if="ingredient.is_local === true"
            class="text-success"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </dd>
        <dt class="col-sm-3 text-right">Label</dt>
        <dd class="col-sm-9">
          <feather-icon
            v-if="ingredient.is_labeled === true"
            class="text-success"
            icon="CheckIcon"
          />
          <span v-else>-</span>
        </dd>
        <dt class="col-sm-3 text-right">Origine</dt>
        <dd class="col-sm-9">
          {{ ingredient.origin || '-' }}
        </dd>
        <dt class="col-sm-3 text-right">Ciqual Alim Code</dt>
        <dd class="col-sm-9">
          {{ ingredient.nutritional_group_code || '-' }}
          <span v-if="ingredient.nutritional_group">
            ({{ ingredient.nutritional_group.alim_name_fr }})
          </span>
          <span
            v-if="
              ingredient.nutritional_group_code && !ingredient.nutritional_group
            "
            class="text-warning"
          >
            (Code inconnu)
          </span>
        </dd>
        <dt class="col-sm-3 text-right">Allergènes</dt>
        <dd class="col-sm-9">
          {{ ingredient.allergens.join(', ') || '-' }}
        </dd>
      </dl>
    </b-card>
    <b-card title="Recettes dans laquelle apparait cet ingrédient">
      <p
        v-if="ingredient.recipes && ingredient.recipes.length == 0"
        class="text-muted text-center"
      >
        <em>
          Cet ingrédient n’est utilisé dans aucun recette pour l’instant.
        </em>
      </p>
      <table class="table table-hover">
        <template v-for="recipe in ingredient.recipes">
          <tr
            :key="recipe.id"
            @click.prevent="$router.push(`/recipes/${recipe.id}`)"
          >
            <td>
              <b>{{ recipe.label }}</b>
              <p class="text-muted">{{ recipe.description }}</p>
            </td>
            <td class="text-right">
              <span class="text-nowrap"> Score {{ recipe.nutri_score }} </span>
            </td>
          </tr>
        </template>
      </table>
    </b-card>
    <!-- <pre>{{ ingredient }}</pre> -->
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import IngredientForm from '@/components/IngredientForm.vue'

export default {
  components: {
    BCard,
    IngredientForm,
  },
  data() {
    return {
      displayIngredientForm: false,
      ingredient: { allergens: [] },
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(
        `/ingredients/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields':
              '*,recipes{id,label,description,nutri_score},nutritional_group',
          },
        }
      )
      this.ingredient = resp.data
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onUpdate() {
      this.init()
      this.displayIngredientForm = false
    },
    onDelete() {
      this.displayIngredientForm = false
      this.$router.push('/ingredients/')
    },
  },
}
</script>

<style></style>
