<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <b-form-group v-if="creation == true" label-for="input-label">
          <validation-provider
            #default="{ errors }"
            name="Famille"
            rules="required"
          >
            <b-form-select
              v-model="form.product_family"
              :options="constants.CATEGORIES_CODES"
              placeholder="Famille"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Choisir une famille --
                </b-form-select-option>
              </template>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-label">
          <validation-provider
            #default="{ errors }"
            name="Ingrédient"
            rules="required"
          >
            <b-form-input
              id="input-label"
              v-model="form.label"
              type="text"
              :state="errors.length > 0 ? false : null"
              placeholder="Intitulé de l’ingredient interne"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-public_label">
          <b-form-input
            id="input-public_label"
            v-model="form.public_label"
            type="text"
            placeholder="Intitulé de l’ingredient public"
          />
        </b-form-group>
        <b-form-group id="input-group-is_organic">
          <b-form-checkbox
            v-model="form.is_excluded_from_public_list"
            :value="true"
            :unchecked-value="false"
          >
            Exclure pourcentage des listes publiques
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label-for="input-suplier">
          <validation-provider
            #default="{ errors }"
            name="Fournisseur"
            rules="required"
          >
            <b-form-input
              id="input-suplier"
              v-model="form.suplier"
              type="text"
              placeholder="Fournisseur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-suplier_reference">
          <b-form-input
            id="input-suplier_reference"
            v-model="form.suplier_reference"
            type="text"
            placeholder="Référence fournisseur"
          />
        </b-form-group>
        <b-form-group label-for="input-unit">
          <validation-provider
            #default="{ errors }"
            name="Fournisseur"
            rules="required"
          >
            <b-form-input
              id="input-unit"
              v-model="form.unit"
              type="text"
              placeholder="Unité de conditionnement (L, KG…)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-price">
          <validation-provider
            #default="{ errors }"
            name="Prix"
            rules="required|double:0,dot"
          >
            <b-form-input
              id="input-price"
              v-model="form.price"
              type="number"
              placeholder="Prix HT (décimale avec un . (point))"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-origin">
          <b-form-input
            id="input-origin"
            v-model="form.origin"
            type="text"
            placeholder="Origine du produit"
          />
        </b-form-group>
        <b-form-group id="input-group-is_organic">
          <b-form-checkbox
            v-model="form.is_organic"
            :value="true"
            :unchecked-value="false"
          >
            Bio
          </b-form-checkbox>
        </b-form-group>
        <b-form-group id="input-group-is_local">
          <b-form-checkbox
            v-model="form.is_local"
            :value="true"
            :unchecked-value="false"
          >
            Local
          </b-form-checkbox>
        </b-form-group>
        <b-form-group id="input-group-is_labeled">
          <b-form-checkbox
            v-model="form.is_labeled"
            :value="true"
            :unchecked-value="false"
          >
            Label
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label-for="input-nutritional_group_code">
          <b-form-input
            id="input-nutritional_group_code"
            v-model="form.nutritional_group_code"
            type="text"
            placeholder="Ciqual Alim Code"
          />
        </b-form-group>
        <b-form-group label="Allergènes">
          <b-form-checkbox-group
            v-if="form.allergens"
            id="checkbox-group-2"
            v-model="form.allergens"
            name="allergens"
          >
            <b-form-checkbox
              v-for="allergen in constants.ALLERGENS"
              :key="allergen"
              :value="allergen"
            >
              {{ allergen }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="ingredientId"
              v-b-modal.modal-delete
              variant="danger"
            >
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-ingredient"
      centered
      hide-header-close
      title="Suprimmer l’ingrédient"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteIngredient()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }}</pre> -->
    <!-- <pre>{{ constants }}</pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'CopyValue',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    ingredientId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      constants: {},
      required,
      double,
      locale: 'fr',
      form: {
        label: null,
        public_label: null,
        is_excluded_from_public_list: null,
        product_family: null,
        suplier: null,
        suplier_reference: null,
        nutritional_group_code: null,
        unit: null,
        price: null,
        origin: null,
        is_organic: false,
        is_local: false,
        is_labeled: false,
        allergens: [],
      },
    }
  },
  watch: {
    ingredientId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'ALLERGENS,CATEGORIES_CODES' },
      })
      this.constants = respConstants.data
      if (this.ingredientId) {
        const resp = await this.$http.get(
          `/ingredients/${this.ingredientId}/`,
          {
            headers: {
              'X-Fields': Object.keys(this.form).join(','),
            },
          }
        )
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.ingredientId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/ingredients/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        // console.log(JSON.parse(JSON.stringify(resp.data)))
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/ingredients/${this.ingredientId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async deleteIngredient() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/ingredients/${this.ingredientId}/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-Keys': Object.keys(this.form).join(','),
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('deleted', resp.data)
      this.$refs['delete-ingredient'].hide()
    },
  },
}
</script>
